import type { AppointmentFormTypes as Types } from '@grandvisionhq/appointments'

export const consents: Types.ConsentOptIn[] = [
  {
    title: 'appointments.consent.createAccount',
    value: 'createAccount',
    required: true,
  },
]

export const optIns: Types.ConsentOptIn[] = [
  // single optin
  {
    title: 'appointments.optIn.directMail',
    value: 'direct-mail',
  },
]
