import { CommerceToolsApi } from '@grandvisionhq/commercetools'
import type { ProductDetailProvider } from '@grandvisionhq/products'

type SpecificationConfiguration = React.ComponentPropsWithoutRef<
  typeof ProductDetailProvider
>['specificationConfiguration']

//TODO: update this if needed
export function getSpecifications(productType: string): SpecificationConfiguration | undefined {
  if (
    CommerceToolsApi.isSunglasses(productType) ||
    CommerceToolsApi.isFrames(productType) ||
    CommerceToolsApi.isReadyReaders(productType)
  ) {
    return [
      {
        labelKey: 'products.specifications.lensColor',
        attribute: 'lensColor',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameColor',
        attribute: 'frameColor',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameMaterial',
        attribute: 'frameMaterial',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameShape',
        attribute: 'frameShape',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameType',
        attribute: 'frameType',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.gender',
        attribute: 'gender',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.roadworthiness',
        attribute: 'roadworthiness',
        labelPrefix: 'products.specifications.roadworthiness.',
      },
      {
        labelKey: 'products.specifications.opcoBackendId',
        attribute: 'opcoBackendId',
        isLocalised: false,
      },
      {
        labelKey: 'products.specifications.erpId',
        attribute: 'erpId',
        isLocalised: false,
      },
    ]
  }

  if (productType === 'Contact-lenses') {
    return [
      {
        labelKey: 'products.contactLenses.materialType',
        attribute: 'materialType',
        isLocalised: true,
      },
      {
        labelKey: 'products.contactLenses.lensType',
        attribute: 'lensType',
        isLocalised: true,
      },
      {
        labelKey: 'products.contactLenses.material',
        attribute: 'material',
        isLocalised: true,
      },
      {
        labelKey: 'products.contactLenses.waterContent',
        attribute: 'waterContent',
        isLocalised: true,
      },
      {
        labelKey: 'products.contactLenses.features.uv',
        attribute: 'features.uv',
        isLocalised: true,
      },
      {
        labelKey: 'products.contactLenses.boxQuantity',
        attribute: 'packageQuantity',
      },
      {
        labelKey: 'products.specifications.opcoBackendId',
        attribute: 'opcoBackendId',
        isLocalised: false,
      },
      {
        labelKey: 'products.specifications.erpId',
        attribute: 'erpId',
        isLocalised: false,
      },
    ]
  }

  if (productType === 'Accessories') {
    return [
      {
        labelKey: 'products.specifications.accessoryColor',
        attribute: 'accessoryColor',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.material',
        attribute: 'material',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.opcoBackendId',
        attribute: 'opcoBackendId',
        isLocalised: false,
      },
      {
        labelKey: 'products.specifications.erpId',
        attribute: 'erpId',
        isLocalised: false,
      },
    ]
  }
}
